import React from "react";
import Layout from '../../components/Layout'
import { Link } from 'gatsby'

export default () => (
  <Layout>
    <section className="section">
      <div className="container content-border">
        <div className="content has-text-centered">
            <p><strong>Thank you!</strong></p>
            <hr />
            <p>Your submission has been received.</p>
            <p>Please note that these messages are only monitored during business hours and it may take a business day or more for a response, depending on your enquiry.</p>
            <Link className="button is-small is-primary" to='/'>
              ← Return Home
            </Link>
        </div>
      </div>
      </section>
  </Layout>
);